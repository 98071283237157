import React from "react";

import { DefaultLayout } from "../components/layout/defaultLayout";
import { CategoryWrapper } from "../components/wrapper/categoryWrapper";

const CategoryPage = () => {
  return (
    <DefaultLayout pageTitle="カテゴリ一覧" headerTitle="CATEGORY">
      <CategoryWrapper />
    </DefaultLayout>
  );
};

export default CategoryPage;
